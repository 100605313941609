<template>
    <div id="app" class="wrapper" ref="wrapper">
        <Top v-if="routeFlag != 1"></Top>
        <transition name="fade-transform" mode="out-in">
            <template v-if="$route.meta.keepAlive">
                <keep-alive>
                    <router-view class="view"/>
                </keep-alive>
            </template>
            <template v-else>
                <router-view class="view"/>
            </template>

        </transition>
        <Bottom v-if="routeFlag != 1"></Bottom>
        <!-- 联系我们 -->
        <div class="contactUs">
<!--            <div class="item">-->
<!--                <div class="tel ">-->
<!--                    <div class="flex">-->
<!--                        <img src="@/assets/images/tel.jpg"/>-->
<!--                        <div class="telInfo">-->
<!--                            <p>联系电话</p>-->
<!--                            <p>{{tel}}</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <img src="@/assets/images/lianxiwomen.png" alt="">-->
<!--                    <div>联系我们</div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="item" @click="backTop">
                <div>
                    <img src="@/assets/images/fanhuidingbu.png" alt="">
                    <div>返回顶部</div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import Top from "@/components/top.vue";
    import Bottom from "@/components/bottom.vue";

    export default {
        components: {
            Top,
            Bottom,
        },
        data() {
            return {
                routeFlag: 0,
                tel: '',
            };
        },
        watch: {
            $route: {
                handler(val) {
                    if (val.name == "login") {
                        //绑定机构 不需要头尾
                        this.routeFlag = 1;
                    } else {
                        this.routeFlag = 0;
                    }
                },
                // 深度观察监听
                deep: true,
            },
        },
        created() {
            this.getTel()
        },
        methods: {
            backTop() {
                this.$refs.wrapper.scrollTop = 0
            },
            // toContactUs() {
            //   window.location.href = `tel://${this.tel}`
            // },
            getTel() {
                this.$http({
                    url: `/configApi/contactTel`,
                    method: "POST",
                }).then((res) => {
                    if (res.state == 10200) {
                        this.tel = res.data
                    }

                });
            }
        }
    };
</script>
<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: #5B2528;
        color: #333;
        position: relative;
        font-size: 14px;
        min-height: 100vh;
    }

    .contactUs {
        position: fixed;
        right: 0px;
        top: 50%;
        width: 80px;
        z-index: 99999;

        .item {
            cursor: pointer;
            background: #7D2626;
            color: #FFF;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            position: relative;

            &:hover {
                .tel {
                    display: inline-block;
                }
            }

            .tel {
                display: none;
                background: #FFF;
                position: absolute;
                top: 0;
                left: -165px;
                color: #000000;
                min-width: 150px;
                justify-content: center;
                align-items: center;
                border-radius: 5px;

                &::after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-width: 10px;
                    border-style: solid;
                    border-color: transparent transparent transparent #FFF;
                    position: absolute;
                    top: 50%;
                    margin-top: -10px;
                    right: -19px;
                }

                .flex {
                    justify-content: center;
                    align-items: center;
                    height: 80px;
                }

                img {
                    width: 30px;
                    height: 30px;
                    margin: 0;
                    margin-right: 10px;

                }

                .telInfo {
                    text-align: left;
                }

            }

            img {
                width: 40px;
                height: 40px;
                margin: 0 auto;
                margin-bottom: 5px;
            }

            &:last-of-type {
                background: #000000 !important;
            }
        }

    }

    /* fade */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.28s;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }

    /* fade-transform */
    .fade-transform-leave-active,
    .fade-transform-enter-active {
        transition: all 0.5s;
    }

    .fade-transform-enter {
        opacity: 0;
        transform: translateX(-100px);
    }

    .fade-transform-leave-to {
        opacity: 0;
        transform: translateX(100px);
    }

    .line1 {
        overflow: hidden; /*自动隐藏文字*/
        text-overflow: ellipsis; /*文字隐藏后添加省略号*/
        white-space: nowrap; /*强制不换行*/
    }

    .line2 {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        /* -webkit-box-orient: vertical; */
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: -webkit-box;
        word-break: break-all;
    }

    .nav_top {
        background: #F1F1F1;
        padding: 15px 60px;
        font-size: 18px;
        color: #595E63;
    }

    .el-message__icon {
        font-size: 16px
    }

    .noData {
        width: 546px;
        height: 546px;
        margin: 0 auto;
    }

    .coverimg-big {
        display: inline-block;
        max-width: 600px;
        max-height: 600px;
        width: auto;
        height: auto;
    }
</style>
