import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/index.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/login',
		name: 'login',
		component:() => import('@/views/Login/login'),
		
	},
	{
		path: '/findPwd',
		name: 'login',
		component:() => import('@/views/Login/findPwd'),
		
	},
	// 景区导览
	{
		path: '/guide',
		name: 'guide',
		component:() => import('@/views/Guide/index'),
		
	},
	// banner详情
	{
		path: '/banner/detail',
		name: 'detail',
		component: () => import('@/views/Banner/detail'),
	},
	// 信息公开
	{
		path: '/information',
		name: 'information',
		component:() => import('@/views/Information/index'),
	},
	{
		path: '/information/detail',
		name: 'information',
		component:() => import('@/views/Information/detail'),
	},
	{
		path: '/information/bannerDetail',
		name: 'information',
		component: () => import('@/views/Information/bannerDetail'),
	},
	// 馆藏精品
	{
		path: '/boutique',
		name: 'boutique',
		component:() => import('@/views/Boutique/index'),
	},
	{
		path: '/boutique/detail',
		name: 'boutique',
		component:() => import('@/views/Boutique/detail'),
	},
	{
		path: '/boutique/bannerDetail',
		name: 'boutique',
		component: () => import('@/views/Boutique/bannerDetail'),
	},
	// 网上祭扫
	{
		path: '/sweep',
		name: 'sweep',
		component:() => import('@/views/Sweep/index'),
	},
	{
		path: '/sweep/detail',
		name: 'sweep',
		component:() => import('@/views/Sweep/detail'),
	},
	{
		path: '/sweep/bannerDetail',
		name: 'sweep',
		component: () => import('@/views/Sweep/bannerDetail'),
	},
	// 预约服务
	{
		path: '/reservation',
		name: 'reservation',
		component:() => import('@/views/Reservation/index'),
	},
	{
		path: '/reservation/xuzhi',
		name: 'reservation',
		component:() => import('@/views/Reservation/xuzhi'),
	},
	{
		path: '/reservation/step_one',
		name: 'reservation',
		component:() => import('@/views/Reservation/step_one'),
	},
	{
		path: '/reservation/step_two',
		name: 'reservation',
		component:() => import('@/views/Reservation/step_two'),
	},
	{
		path: '/reservation/step_three',
		name: 'reservation',
		component:() => import('@/views/Reservation/step_three'),
	},
	{
		path: '/reservation/step_four',
		name: 'reservation',
		component:() => import('@/views/Reservation/step_four'),
	},
	// 学术研究
	{
		path: '/yanjiu',
		name: 'yanjiu',
		component:() => import('@/views/Yanjiu/index'),
	},
	{
		path: '/yanjiu/bannerDetail',
		name: 'yanjiu',
		component: () => import('@/views/Yanjiu/bannerDetail'),
	},
	// 社会教育
	{
		path: '/jiaoyu',
		name: 'jiaoyu',
		component: () => import('@/views/Jiaoyu/index'),
	},
	{
		path: '/jiaoyu/detail',
		name: 'jiaoyu',
		component: () => import('@/views/Jiaoyu/detail'),
	},
	{
		path: '/jiaoyu/bannerDetail',
		name: 'jiaoyu',
		component: () => import('@/views/Jiaoyu/bannerDetail'),
	},
	// 我的预约
	{
		path: '/mine',
		name: 'mine',
		component: () => import('@/views/Mine/index'),
	},
	{
		path: '/mine/detail',
		name: 'mine',
		component: () => import('@/views/Mine/detail'),
	},


]
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
