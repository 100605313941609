<template>
	<div class="container_top">
		<div class="w1200 flex_a">
			<img src="@/assets/images/logo.png"/>
			<div class="right">
				<div class="right_top">
					<p v-if="userInfo">欢迎，{{userInfo.account}}<span @click="toMine">我的预约</span><span @click="loginOut">退出登录</span></p>
					<p v-else>
						<label @click="toRegister">注册</label>
						<label @click="toLogin">登录</label>
					</p>
				</div>
				<ul class="flex_a right_bottom">
					<li @click="handleChangeTab(index+1)" :class="{'checked' : tabIndex == index+1}" v-for="(item,index) in list" :key="index">
						{{item.name}}
						<div class="dl" v-if="index == 1" >
							<div @click.stop="changeDL(index,idx+1)" :class="{'sjchecked' : jqIndex == idx+1}"  v-for="(item,idx) in jqList" :key="idx">{{item.titles}}</div>
						</div>
						<div class="dl" v-if="index == 7">
							<div @click.stop="changeDL(index,idx+1)" :class="{'sjchecked' : sjIndex == idx+1}" v-for="(item,idx) in classifyList" :key="idx">{{item.name}}</div>
						</div>
						<div class="dl" v-if="index == 2">
							<div @click.stop="changeDL(index,idx+1)" :class="{'sjchecked' : infoIndex == idx+1}" v-for="(item,idx) in infoList" :key="idx">{{item.name}}</div>
						</div>
						<div class="dl" v-if="index == 6">
							<div @click.stop="changeDL(index,idx+1)" :class="{'sjchecked' : yjIndex == idx+1}" v-for="(item,idx) in yanjiuList" :key="idx">{{item.name}}</div>
						</div>
						<div class="dl" v-if="index == 3">
							<div @click.stop="changeDL(index,idx+1)" :class="{'sjchecked' : gcIndex == idx+1}" v-for="(item,idx) in guancangList" :key="idx">{{item.name}}</div>
						</div>
					</li>
				</ul>
			</div>
			
		</div>
		
		
	</div>
	
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		name: 'top',
		data(){
			return{
				list: [
					{name: '首页'},
					{name: '景区导览'},
					{name: '信息公开'},
					{name: '馆藏精品'},
					{name: '网上祭扫'},
					{name: '预约服务'},
					{name: '学术研究'},
					{name: '社会教育'}
				],
				tabIndex: 1,
				token: '',
				userInfo: null,
				sjIndex: '',
				infoIndex: '',
				yjIndex: '',
				gcIndex: '',
				jqIndex: '',
				jqList: [],
				classifyList: [],
				infoList: [],
				yanjiuList: [],
				guancangList: [],
				
			}
		},
		watch: {
			$route: {
				handler(val) {
					if(val.name == 'home'){
						this.tabIndex = 1
					}
					if(val.name == 'guide'){
						this.jqIndex = sessionStorage.getItem('jqIndex')
						this.tabIndex = 2
					}
					if(val.name == 'information'){
						this.infoIndex = sessionStorage.getItem('infoIndex')
						this.tabIndex = 3
					}
					if(val.name == 'boutique'){
						this.gcIndex = sessionStorage.getItem('gcIndex')
						this.tabIndex = 4
					}
					if(val.name == 'sweep'){
						this.tabIndex = 5
					}
					if(val.name == 'reservation'){
						this.tabIndex = 6
					}
					if(val.name == 'yanjiu'){
						this.yjIndex = sessionStorage.getItem('yjIndex')
						this.tabIndex = 7
					}
					if(val.name == 'jiaoyu'){
						this.sjIndex = sessionStorage.getItem('sjIndex')
						this.tabIndex = 8
					}
					if(val.name == 'mine'){
						this.tabIndex = 9
					}

				}
			}
		},
		computed:{
		
		},
		mounted(){
			//景区导览 默认选中
			this.$store.commit('SET_JQ',sessionStorage.getItem('jqIndex'))
			this.jqIndex = parseInt(sessionStorage.getItem('jqIndex'))
			//社会教育 默认选中
			this.$store.commit('SET_SJ',sessionStorage.getItem('sjIndex'))
			this.sjIndex = parseInt(sessionStorage.getItem('sjIndex'))
			//信息公开 默认选中
			this.$store.commit('SET_INFO',sessionStorage.getItem('infoIndex'))
			this.infoIndex = parseInt(sessionStorage.getItem('infoIndex'))

			//学术研究 默认选中
			this.$store.commit('SET_YJ',sessionStorage.getItem('yjIndex'))
			this.yjIndex = parseInt(sessionStorage.getItem('yjIndex'))

			//馆藏 默认选中
			this.$store.commit('SET_GC',sessionStorage.getItem('gcIndex'))
			this.gcIndex = parseInt(sessionStorage.getItem('gcIndex'))

			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			this.getJq()
			this.getClassify()
			this.getInfo()
			this.getYanJiu()
			this.getGuanCang()
		},
		methods: {
			// 获取景区导览下啦列表
			getJq() {
				this.$http({
					url: `/configApi/scenicSpotGuide`,
					method: "POST",
				}).then((res) => {
					if(res.state == 10200){
						this.jqList = res.data
					}
					
				});
			},
			// 获取信息公开下啦列表
			getInfo() {
				this.$http({
					url: `/infoDisclosureClassApi/queryAllInfoDisclosureClass`,
					method: "POST",
				}).then((res) => {
					if(res.state == 10200){
						this.infoList = res.data
					}
					
				});
			},
			// 获取学术研究下啦列表
			getYanJiu() {
				this.$http({
					url: `/academicResearchClassApi/queryAllAcademicResearchClass`,
					method: "POST",
				}).then((res) => {
					if(res.state == 10200){
						this.yanjiuList = res.data
					}
					
				});
			},
			// 获取社会教育下啦列表
			getClassify() {
				this.$http({
					url: `/socialEducationClassApi/queryAllSocialEducationClass`,
					method: "POST",
				}).then((res) => {
					if(res.state == 10200){
						this.classifyList = res.data
					}
					
				});
			},
			// 获取馆藏下啦列表
			getGuanCang() {
				this.$http({
					url: `/collectBoutiqueClassApi/queryAllCollectBoutiqueClass`,
					method: "POST",
				}).then((res) => {
					if(res.state == 10200){
						this.guancangList = res.data
					}
					
				});
			},
			handleChangeTab(index) {
				this.tabIndex = index
				if(index == 1) this.$router.push('/')
				if(index == 2) {
					this.changeDL(1,1)
				}
				if(index == 3) this.changeDL(2,1)
				// if(index == 4) this.$router.push('/boutique')
				if(index == 4) {
					this.changeDL(3,0)
				}
				if(index == 5) this.$router.push('/sweep')
				if(index == 6) this.$router.push('/reservation')
				if(index == 7) this.changeDL(6,1)
				if(index == 8) this.changeDL(7,1)
			},
			changeDL(type,index) {
				console.log(type + '--' + index);
				if(type == 1){
					sessionStorage.setItem('jqIndex',index)
					this.jqIndex = index
					this.$store.commit('SET_JQ',index)
					this.$router.push('/guide')
				}
				if(type == 7){
					sessionStorage.setItem('sjIndex',index)
					this.sjIndex = index
					this.$store.commit('SET_SJ',index)
					this.$router.push('/jiaoyu')
				}
				if(type == 2){
					sessionStorage.setItem('infoIndex',index)
					this.infoIndex = index
					this.$store.commit('SET_INFO',index)
					this.$router.push('/information')
				}
				if(type == 6){
					sessionStorage.setItem('yjIndex',index)
					this.yjIndex = index
					this.$store.commit('SET_YJ',index)
					this.$router.push('/yanjiu')
				}
				if(type == 3){
					sessionStorage.setItem('gcIndex',index)
					this.gcIndex = index
					this.$store.commit('SET_GC',index)
					this.$router.push('/boutique')
				}
				
			},
			toMine() {
				this.$router.push('/mine')
			},
			toLogin() {
				this.$router.push({ path: "/login"});
			},
			toRegister() {
				this.$router.push({ path: "/login",query:{type:2}});
			},
			loginOut() {
				this.$confirm('确定要退出吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
					}).then(() => {
						localStorage.removeItem('userInfo')
						this.userInfo = null
						this.$message({
							type: 'success',
							message: '退出成功!',
						});
						this.$router.replace('/login')
					}).catch(() => {});
			},
			
		}
	}
</script>

<style lang="scss" scoped>
.container_top{
	height: 145px;
	background: url('../assets/images/daohang.png') no-repeat;
	background-size: cover;
	display: flex;
	position: relative;
	.right{
		color: #FFF;
		margin-left: auto;
		.right_top{
			text-align: right;
			margin-bottom: 30px;
			span{
				cursor: pointer;
				position: relative;
				&:nth-of-type(1){
					margin-left: 20px;
					margin-right: 40px;
					&::after{
						content: '';
						position: absolute;
						right: -20px;
						width: 1px;
						height: 16px;
						top: 50%;
						margin-top: -8px;
						background: #FFF;
					}
				}
			}
			label{
				border: 1px solid #FFF;
				border-radius: 5px;
				padding: 5px 7px;
				cursor: pointer;
				&:nth-of-type(1){
					margin-right: 20px;
				}
			}
		}
		.right_bottom{
			font-size: 24px;
			li{
				text-align: right;
				margin-left: 30px;
				cursor: pointer;
				position: relative;
				height: 45px;
				line-height: 42px;
				&:hover{
					.dl{
						display: block;
					}
				}
				.dl{
					position: absolute;
					right: 50%;
					top: 46px;
					display: none;
					font-size: 16px;
					background: #A5343C;
					text-align: center;
					width: 120px;
					margin-right: -60px;
					padding: 10px 0;
					z-index: 99999;
					&::after{
						content: '';
						 width: 0;
						height: 0;
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
						border-bottom: 12px solid #A5343C;
						position: absolute;
						right: 50%;
						top: -12px;
						margin-right: -6px;
					}
					>div{
						height: 30px;
						line-height: 30px;
						&:hover{
							background: #6F0E14;
						}
						
					}
					.sjchecked{
						background: #6F0E14;
					}
					
					
				}
			}
			.checked{
				&::after{
					position: absolute;
					content: '';
					width: 20px;
					height: 2px;
					background: #FFF;
					left: 50%;
					margin-left: -10px;
					top: 50px;

				}
			}
		}
	}
	
}
	
</style>
