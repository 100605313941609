<template>
	<div class="container">
		<div class="wapper w1200">
			<el-carousel :interval="5000" arrow="always">
				<el-carousel-item v-for="(item,index) in bannerList" :key="index">
					<img class="banner"  :src="item.pic" alt="" @click="toBanner(item.includeContent,item.infoDisclosureId,index)">
				</el-carousel-item>
			</el-carousel>
			
			<div class="news flex">
				<div class="left">
					<div class="title flex_a">
						<span>信息公开</span>
						<label @click="toInformation">更多</label>
					</div>
					<div class="main1 flex">
						<ul class="nav">
							<li @click="handleChangeNav(index)" :class="{'checked' : navIndex == index}" v-for="(item,index) in classifyList" :key="index">{{item}}</li>
						</ul>
						<div class="content" v-if="infoList&& infoList.length > 0">
							<img :src="infoList[0].cover"/>
							<h2 @click="toInfoDetail(infoList[0].id)">{{infoList[0].title}}</h2>
							<p>{{infoList[0].releaseTime}}</p>
							<ul>
								<li  v-for="(item,idx) in infoList" :key="idx" v-if="idx > 0" class="flex" @click="toInfoDetail(item.id)"><p class="line1">{{item.title}}</p><span>{{item.releaseTime}}</span></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="title flex_a">
						<span>社会教育</span>
						<label @click="toJiaoyu">更多</label>
					</div>
					<ul>
						<li v-for="(item,index) in socialList" :key="index" class="flex" @click="toJiaoyuDetail(item.id)"><p class="line1">{{item.title}}</p><span>{{item.releaseTime}}</span></li>
					</ul>
					<div class="img" @click="toSweep">
						<img src="@/assets/images/wangshangjisao.png"/>
					</div>
					<div class="img" @click="toYuyue">
						<img src="@/assets/images/yuyuefuwu.png"/>
					</div>

				</div>
			</div>
			<div class="boutique">
				<div class="title"><span>馆藏精品</span></div>
				<ul>
					<li class="flex"><img src="@/assets/images/dian.png"/>经过50余年的征集整理，目前纪念馆拥有藏品2万余件，主要为淮海战役期间的纸、布、铁、皮等质地的文物和历史图片、史料及国家领导人、社会知名人士的题词、书画作品等，其中尤以战役期间的命令、日记、地图、武器装备、支前用品、领导人题词等藏品弥为珍贵。</li>
					<li class="flex"><img src="@/assets/images/dian.png"/>这些藏品，是淮海战役历史的真实见证与生动记录，是近距离了解淮海战役历史的珍贵资料，具有较高的历史价值和文物价值。</li>
				
				</ul>
				<el-row :gutter="20" v-if="collectList[0]">
					<el-col :span="12"><div class="imgHover" @click="toBoutique(collectList[0].id,1)"><img :src="collectList[0].cover"/></div></el-col>
					<el-col :span="12" v-if="collectList[1]"><div class="imgHover" @click="toBoutique(collectList[1].id),2"><img :src="collectList[1].cover"/></div></el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="8" v-for="(item,index) in collectList" :key="index" v-if="index > 1 && index < 4">
						<div class="imgItem" @click="toBoutique(item.id,3)">
							<img :src="item.cover"/>
							<div class="info">
								<p class="line1">{{item.name}}</p>
								<!-- <p class="line1">供春紫砂壶供春紫砂壶供春紫砂壶供春紫砂壶供春紫砂壶</p> -->
							</div>
						</div>
					</el-col>
				</el-row>
				<div class="more">
					<span @click="toBoutique()">查看更多</span>
				</div>


			</div>
			<!-- 学术研究 -->
			<div class="research">
				<div class="title"><span>学术研究</span></div>
				<div class="flex">
					<div class="left">
						<dl>
							<dd :class="{'checked' : dtoIndex == index}" @click="handleDto(index)" v-for="(item,index) in classDtoList" :key="index">{{item}}</dd>
						</dl>
					</div>
					<div class="right">
						<el-row :gutter="20">
							<el-col :span="6" v-for="(item,index) in academicList" :key="index">
								<div class="right_info" @click="toOpen(item.fileUrl)">
									<img src="@/assets/images/wenjian.png" alt="">
									<p class="line1">{{item.title}} </p>
								</div>
							</el-col>
							
						</el-row>
						<!-- <ul class="flex">
							<li v-for="(item,index) in 5" :key="index">
								<img src="@/assets/images/moren.png" alt="">
								<p>淮海战役烈士纪念塔碑文 </p>
							</li>
						</ul> -->

					</div>

				</div>
				<div class="more">
					<span @click="toYanjiu">查看更多</span>
				</div>
				

			</div>

		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				navList: ['党建要闻','党建要闻','党建要闻'],
				navIndex: 0,
				bannerList: [],
				infoList: [],//当前分类下的信息列表
				classifyList: [],//信息分类
				infoAllList: [], //全部分类 全部信息列表
				socialList: [],
				collectList: [],
				classDtoList: [], //馆藏精品分类
				academicList: [], //当前分类下的馆藏精品
				academicAllList: [], //全部分类 全部馆藏精品
				dtoIndex: 0,
			}
		},
		created() {
			
		},
		mounted() {
			this.getIndex()
		},
		methods: {
			getIndex() {
				this.$http({
						url: `/pcIndexApi/indexInfo`,
						method: "POST",
					}).then((res) => {
						if(res.state == 10200){
							this.bannerList = res.data.pcIndexBannersList
							this.infoAllList = res.data.infoDisclosureClassDtoList
							this.infoAllList.forEach(element => {
								this.classifyList.push(element.name)
							});
							this.infoList = this.infoAllList[this.navIndex].infoDisclosureList
							this.socialList = res.data.socialEducationList
							this.collectList = res.data.collectBoutiqueClassList

							this.academicAllList = res.data.academicResearchClassDtoList
							this.academicAllList.forEach(element => {
								this.classDtoList.push(element.name)
							});
							this.academicList = this.academicAllList[this.dtoIndex].academicResearchList
						}
						
					});
			},
			handleChangeNav(index) {
				this.navIndex = index
				this.infoList = this.infoAllList[this.navIndex].infoDisclosureList
				this.$forceUpdate()
				// this.infoList = this.infoAllList[index]
			},
			handleDto(index) {
				this.dtoIndex = index
				this.academicList = this.academicAllList[this.dtoIndex].academicResearchList
				this.$forceUpdate()
			},
			toBanner(state,id,index) {
				if(state == 1){ //跳转富文本
					this.$router.push({ path: "/information/detail",query:{id:id}});
				}else{
					this.$router.push({ path: "/banner/detail",query:{index:index}});
				}
				
			},
			toInformation() {
				sessionStorage.setItem('infoIndex',1)
				this.$store.commit('SET_INFO',1)
				this.$router.push('/information')
			},
			toInfoDetail(id) {
				this.$router.push({ path: "/information/detail",query:{id:id}});
			},
			toJiaoyu() {
				sessionStorage.setItem('sjIndex',1)
				this.$store.commit('SET_SJ',1)
				this.$router.push('/jiaoyu')
			},
			toJiaoyuDetail(id) {
				this.$router.push({ path: "/jiaoyu/detail",query:{id:id}});
			},
			toSweep() {
				this.$router.push({ path: "/sweep"});
			},
			toYuyue() {
				this.$router.push({ path: "/reservation"});
			},
			toBoutique(id,index) {
				if(index){
					sessionStorage.setItem('gcIndex',index)
					this.$store.commit('SET_GC',index)
					this.$router.push('/boutique')
				}else{
					sessionStorage.setItem('gcIndex',0)
					this.$store.commit('SET_GC',0)
					this.$router.push({ path: "/boutique"});
				}
				
			},
			toYanjiu() {
				sessionStorage.setItem('yjIndex',1)
				this.$store.commit('SET_YJ',1)
				this.$router.push('/yanjiu')
			},
			toOpen(file) {
				window.open(file)
			}
		}
	}
</script>

<style lang="scss" scoped>
.container{
	background: #5B2528;
	.wapper{
		::v-deep .el-carousel__container{
			height: 800px;
		}
		.banner{
			height: 800px;
			width: 100%;
			display: block;
			cursor: pointer;
		}
		.title{
			span{
				display: inline-block;
				width: 181px;
				background: url('../../assets/images/icon.png') no-repeat;
				background-position: center;
				text-align: center;
				font-weight: bold;
				font-size: 25px;
			}
			label{
				margin-left: auto;
				color: #ABABAB;
				position: relative;
				font-size: 16px;
				font-weight: bold;
				cursor: pointer;
				&::after{
					content: '';
					width: 34px;
					height: 34px;
					background: url('../../assets/images/gengduo.png') no-repeat;
					position: absolute;
					bottom: -20px;
					left: 0;
				}
			}
		}
		.more{
			text-align: center;
			padding: 30px 0 45px 0;
			span{
				position: relative;
				font-size: 24px;
				cursor: pointer;
				&::after{
					content: '';
					width: 34px;
					height: 34px;
					background: url('../../assets/images/gengduo.png') no-repeat;
					position: absolute;
					bottom: -30px;
					margin-left: -17px;
					left: 50%;
				}
			}
			
		}
		.news{
			background: #FFF;
			padding: 30px 60px;
			.left{
				width: 615px;
				.main1{
					margin-top: 30px;
					.nav{
						margin-right: 30px;
						li{
							width: 120px;
							height: 48px;
							line-height: 48px;
							background: #DCDCDC;
							color: #595E63;
							text-align: center;
							cursor: pointer;
						}
						.checked{
							background: #A5343C;
							color: #FFF;
						}
					}
					.content{
						flex: 1;
						min-width: 0;
						font-size: 16px;
						img{
							width: 100%;
							height: 270px;
							border-radius: 5px;
						}
						h2{
							font-size: 25px;
							line-height: 40px;
							font-weight: bold;
							margin-top: 10px;
							max-height: 80px;
							overflow: hidden;
							cursor: pointer;
						}
						>p{
							color: #ABABAB;
							border-bottom: 1px dashed #ABABAB;
							padding: 10px 0;
						}
						ul{
							li{
								font-size: 16px;
								color: #616A78;
								padding: 13px 0;
								border-bottom: 1px dashed #DCDCDC;
								cursor: pointer;
								&:hover{
									color: #A5343C;
								}
								p{
									flex: 1;
								}
								span{
									color: #ABABAB;
									margin-left: 20px;
								}
							}
						}
					}
				}
			}
			.right{
				flex: 1;
				min-width: 0;
				margin-left: 30px;
				font-size: 16px;
				ul{
					margin-top: 20px;
					li{
						color: #616A78;
						padding: 13px 0;
						border-bottom: 1px dashed #DCDCDC;
						cursor: pointer;
						&:hover{
							color: #A5343C;
						}
						p{
							flex: 1;
						}
						span{
							color: #ABABAB;
						}
					}
				}
				.img{
					margin-top: 15px;
					cursor: pointer;
				}
			}
		}
		// 精品
		.boutique{
			background: #F1F1F1;
			padding: 0 60px;
			.title{
				text-align: center;
				padding: 40px 0 30px 0;
			}
			ul{
				li{
					font-size: 16px;
					margin-bottom: 10px;
					img{
						margin-right: 15px;
						width: 8px;
						height: 8px;
						float: left;
						margin-top: 6px;
					}
				}
			}
			.el-row{
				margin-top: 30px;
			}
			.imgHover{
				cursor: pointer;
				width: 530px;
				height: 280px;
				img{
					width: 530px;
					height: 280px;
				}
				&:hover{
					img{
						width: 530px;
						height: 280px;
						transition:all 0.7s;
						box-shadow: 0px 0px 20px #999;
						transform:translateY(-15px);
						-webkit-transform:translateY(-15px)
						
					}
				}
			}
			.imgItem{
				cursor: pointer;
				font-size: 16px;
				img{
					width: 100%;
					height: 240px;
				}
				&:hover{
					.info{
						background: #A5343C;
					}
				}
				.info{
					background: #353535;
					color: #FFF;
					padding: 8px 15px;
					p{
						margin-bottom: 5px;
						&:last-of-type{
							margin: 0;
						}
					}
				}
			}
			
		}
		// 学术研究
		.research{
			background: #FFF;
			padding: 0 60px;
			.title{
				text-align: center;
				padding: 40px 0 30px 0;
			}
			.left{
				margin-right: 130px;
				dl{
					dt{
						background: #A5343C;
						color: #FFF;
						width: 120px;
						height: 48px;
						line-height: 48px;
						text-align: center;
						font-size: 18px;
					}
					dd{
						background: #F1F1F1;
						text-align: center;
						font-size: 18px;
						color: #595E63;
						width: 120px;
						height: 48px;
						line-height: 48px;
						cursor: pointer;
					}
					.checked{
						position: relative;
						background: #A5343C;
						color: #FFF;
					}
				}
				
			}
			.right{
				flex: 1;
				min-width: 0;
				.right_info{
					color: #595E63;
					cursor: pointer;
					
					img{
						width: 55px;
						height: 80px;
						margin: 0 auto;
					}
					p{
						margin-top: 10px;
						font-size: 16px;
						text-align: center;
					}
				}
				.el-col{
					margin-bottom: 30px;
					&:hover{
						p{
							color: #A5343C;
						}
					}
				}
			}
		}
	}
	
}
	
</style>
