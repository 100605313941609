/* 1.引入文件 */
import axios from 'axios' //引入 axios库
import baseurl from './baseurl.js'
import {Message} from 'element-ui'

const baseURL = baseurl.localUrl

const token = localStorage.getItem('token')
const service = axios.create({
  baseURL,
  timeout: 3000000,
  Authorization: token
})
service.interceptors.request.use(
  (config) => {
    if (config.method=='get') { //判断get请求
      config.params  =  config.params || {};
      config.params.t = Date.parse(new Date())/1000; //添加时间戳  防止接口缓存
    }
    if(!config.type){ //有type  不传token
      if (localStorage.getItem('token')) {
        config.headers.common['Authorization'] = localStorage.getItem('token')
      }
    }
   return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      const data = response.data
      if (data.state != 10200) {
        Message({
          message: data.message,
          type: 'error',
          duration: 2 * 1000,
        })
      }
      return data
    }
  },
  (error) => {
    Message({
      message: error.message,
      type: 'error',
      duration: 2 * 1000,
    })
    return Promise.reject(error)
  }
)

export default service