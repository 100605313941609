import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		sjIndex: '',
		infoIndex: '',
		yjIndex: '',
		gcIndex: '',
		jqIndex: '',
	},
	mutations: {
		SET_JQ(state, jqIndex){
			state.jqIndex = jqIndex
		},
		SET_SJ(state, sjIndex){
			state.sjIndex = sjIndex
		},
		SET_INFO(state, infoIndex) {
			state.infoIndex = infoIndex
		},
		SET_YJ(state, yjIndex) {
			state.yjIndex = yjIndex
		},
		SET_GC(state, gcIndex) {
			state.gcIndex = gcIndex
		}
		
	},
	actions: {
		
	},
	modules: {}
})
